.aboutContainer {
  background: hsl(196, 58%, 12%);
  // padding: 5rem 0 10rem;
  padding: 2rem;
  .section_title {
    text-align: center;
    font-size: 5rem;
    background: -webkit-linear-gradient(
      230deg,
      hsl(0, 0%, 0%),
      hsl(0, 0%, 42%),
      hsl(0, 0%, 0%)
    );

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.grid {
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 300px 1fr;
    margin: 0;
    /* height: 100vh; */
    .section_title {
      grid-row: 1;
      grid-column: 1/13;
      align-self: center;
    }
    .header,
    .about {
      // grid-column: 3/8;
      grid-column: 1/8;
      grid-row: 2;
      z-index: 2;
      // margin-top: 2rem;
    }

    .imgCtn {
      background: gray;
      // margin-top: 5rem;
      grid-column: 8/13;
      grid-row: 2;
      // margin-left: -1rem;
      z-index: 50;
      height: 600px;
      max-width: 350px;
      margin: 0;
      img {
        width: 100%;
        height: 100%;
      }
      //
    }
  }
  @media (min-width: 992px) {
    .section_title {
      align-self: end;
    }
    .header,
    .about {
      grid-column: 2/8;
      margin-top: 2rem;
    }
    .imgCtn {
      // grid-column
      margin-top: 5rem;
    }
  }
  @media (min-width: 1200px) {
    .header,
    .about {
      grid-column: 3/8;
    }
  }
}
.imgCtn {
  background: gray;
  z-index: 50;
  height: 600px;
  max-width: 350px;
  margin: 0 auto;

  //
}
.imgCtn img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  opacity: 0.6;
}

.grid .bg_title {
  display: none;
  // letter-spacing: 5rem;
  @media (min-width: 768px) {
    display: block;
    letter-spacing: 1rem;
    font-size: clamp(140px, 20vw, 358px);
    grid-row: 1/3;
    grid-column: 1/13;
    align-self: start;
    justify-self: center;
    // margin: 0 auto;
    // z-index: 1;
    font-weight: 900;
    line-height: 1;
    background: -webkit-linear-gradient(
      270deg,
      hsl(196, 58%, 10%) 50%,
      hsl(196, 58%, 15%)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: 'Archivo Black', sans-serif;
    /* background: transparent; */
    /* color: black; */
  }
}

.about {
  h2 {
    padding-top: 2rem;
    font-size: 2.2rem;
    color: #d6d6d6;
  }
  h3 {
    font-size: 1.8rem;
    color: #8f8f8f;
    padding-bottom: 2rem;
  }

  p {
    font-size: 1.2rem;
    color: #d6d6d6;
    line-height: 2rem;
    padding-top: 1rem;
    padding-right: 1rem;
  }

  button {
    outline: none;
    border: 1px solid hsl(200, 48%, 50%);
    background: transparent;
    padding: 1em 2em;
    font-weight: bold;
    color: #d6d6d6;
    display: block;
    margin: 2rem auto 0;
    cursor: pointer;
    transition: all 0.6s ease;
    &:hover {
      background: hsl(200, 48%, 50%);
    }
  }
  .aboutList {
    ul {
      list-style: none;
      li {
        font-size: 1.2rem;
        font-weight: 600;
        padding: 1rem 0;

        color: #d6d6d6;
        span {
          margin-left: 0.5rem;
          color: hsl(200, 48%, 50%);
          font-weight: 400;
        }
      }
    }
  }
}
.skills {
  margin: 5rem 0;
}
.skills .bg_title {
  display: none;
  // letter-spacing: 5rem;
  @media (min-width: 768px) {
    display: block;
    text-align: center;
    font-size: 308px;
    align-self: start;
    z-index: -1;
    letter-spacing: 1rem;
    font-size: clamp(100px, 20vw, 358px);
    // font-size: 308px;
    font-weight: 900;
    background: -webkit-linear-gradient(
      270deg,
      hsl(196, 58%, 13%) 70%,
      hsl(196, 58%, 15%)
    );
    background: -webkit-linear-gradient(
      270deg,
      hsl(196, 58%, 10%) 50%,
      hsl(196, 58%, 15%)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.skills .section_title {
  text-align: center;
  font-size: 4.6rem;
  // font-size: 5rem;
  // margin-top: -10rem;
  background: -webkit-linear-gradient(
    230deg,
    hsl(0, 0%, 0%),
    hsl(0, 0%, 42%),
    hsl(0, 0%, 0%)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (min-width: 768px) {
    margin-top: -5rem;
  }
  @media (min-width: 1200px) {
    margin-top: -10rem;
  }
}

.skill_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  // width: 80%;
  margin: 5rem auto 5rem;
  max-width: 1200px;
  @media (min-width: 576px) {
    justify-content: center;
  }
}

.skill-card {
  /* background: black; */
  flex-basis: 22%;
  // justify-content: center;
  /* width: 200px; */
  height: 60px;
  text-align: center;
  display: flex;
  gap: 0.25rem;
  border-radius: 0.25rem;
}

.skill-card p {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 50px;
  background: #2c2c2c;
  padding: 10px;
  margin: 0;
  border-radius: 0.25rem;
  color: white;
  @media (min-width: 576px) {
    width: 100px;
  }
}

.secondRow {
  flex-basis: 18%;
}
.thirdRow {
  flex-basis: 15%;
}

.skill-card .img_ctn {
  width: 30px;
  padding: 0.5rem;
  background: #353535;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skill-card img {
  // width: 40px;
  // height: 40px;
  width: 30px;
  height: 30px;
}

.fade {
  opacity: 1;
}
