.portfolio {
  padding: 10rem 0;
  > h1 {
    text-align: center;
    font-size: 5rem;
  }

  .bg_title {
    display: none;
    @media (min-width: 768px) {
      text-align: center;
      display: block;

      font-size: clamp(140px, 20vw, 358px);
      letter-spacing: 2rem;
      font-weight: 900;
      line-height: 1;
      background: -webkit-linear-gradient(
        270deg,
        hsl(196, 58%, 11%),
        hsl(196, 58%, 8%)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .section_title {
    margin-top: -110px;
    background: -webkit-linear-gradient(
      230deg,
      hsl(0, 0%, 0%),
      hsl(0, 0%, 42%),
      hsl(0, 0%, 0%)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 5rem;
  }
}

.portfolio-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  // padding: 5rem 0;
  flex-direction: column;
  gap: 5rem;
  margin: 0 auto;
  width: 90%;
  max-width: 1300px;
}

.portfolio-card {
  display: flex;
  flex: 30%;
  flex-wrap: wrap;
  // width: 100%;
  // background: white;
  box-shadow: 0 0 30px rgb(0, 0, 0, 30%);
  padding: 1rem;

  height: 300px;
  .line {
    margin-top: 1rem;
    border-bottom: 1px solid #55736c;
    width: 100%;
    height: 1px;
  }
  .row {
    display: flex;
    flex: 100%;
    align-items: center;
    justify-content: space-between;
    align-items: flex-start;
  }

  img {
    width: 100%;
    height: 220px;
    object-fit: cover;
    z-index: 1;
  }

  .card_header {
    span {
      font-size: 60px;
      background: -webkit-linear-gradient(
        270deg,
        hsl(196, 58%, 20%) 10%,
        hsl(195, 58%, 12%)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      line-height: 1;
    }
  }

  h1 {
    font-size: 40px;
    color: hsl(196, 58%, 35%);
  }

  h3 {
    color: #b6b6b6;
    margin: 0;
    font-size: 1.2rem;
    font-weight: 400;
  }
}
/* .portfolio-card h1 {
      /* flex: 0.3; */

.portfolio-card .info {
  // margin-top: -50%;
  justify-content: space-between;
  align-items: center;

  p {
    color: #b6b6b6;
    font-weight: 700;
    margin: 0.25rem 0;
  }
  .btn-ctn {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  a {
    border: none;
    padding: 1em 2em;
    outline: none;
    background: transparent;
    color: hsl(196, 58%, 40%);

    text-align: center;
    font-size: 0.9rem;
    border-radius: 0.25rem;
    border: 1px solid hsl(204, 43%, 41%);
    color: hsl(204, 43%, 51%);
    cursor: pointer;
    transition: all 0.73s ease;
    text-decoration: none;
    &:hover {
      background: hsl(204, 43%, 41%);
      color: white;
    }
  }
}
