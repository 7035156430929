@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
.box {
  height: 100vh;
  min-height: 900px;
  z-index: -1;
  /* background-color: red; */
  @media (min-width: 1600px) {
    height: 120vh;
  }
}

.home {
  // position: relative;
}

canvas {
  z-index: 1;
  /* grid-area: 1/1/1/13; */
  width: 100%;
  // max-width: 1600px;

  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -45%);
  // display: hidden;
  opacity: 0;
  @media (min-width: 992px) {
    opacity: 1;
  }
}
.header_text {
  /* grid-area: 1/3/1/5; */
  /* display: grid; */
  position: absolute;
  /* font-size: 8rem; */
  /* margin-top: 15rem; */
  width: 100%;
  top: 30%;

  left: 0;
  //   text-align: center;
  /* transform: translate(-50%, -20%); */
}
.home {
  h1 {
    // padding-left: 16%;
    text-align: center;

    span {
      /* color: #29815c; */
      /* color: #6b543f; */
      font-size: clamp(28px, 5vw, 72px);
      // font-family: 'Archivo Black', sans-serif;
      letter-spacing: 1.2rem;
      background: -webkit-linear-gradient(
        270deg,
        hsl(192, 56%, 2%),
        hsl(196, 58%, 9%)
      );
      background: -webkit-linear-gradient(
        270deg,
        hsl(196, 58%, 15%),
        hsl(0, 0%, 15%) 50%
      );
      background: -webkit-linear-gradient(
        270deg,
        hsl(196, 58%, 40%) 10%,
        hsl(195, 58%, 8%)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      z-index: 10;
      left: 50%;
      margin: 0;
      line-height: 1em;
      display: inline-block;
    }
    .iam {
      font-family: 'Secular One', sans-serif;
      color: white;

      font-size: clamp(48px, 10vw, 144px);
      background: -webkit-linear-gradient(
        270deg,
        hsl(196, 58%, 8%),
        hsl(195, 58%, 40%)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @media (min-width: 992px) {
        margin-bottom: clamp(300px, 20vw, 16%);
      }
    }
    .name {
      font-size: 4rem;
      padding: 5rem 0;
      @media (min-width: 992px) {
        display: none;
      }
    }
  }
}
.header_text > :nth-child(2) {
  // margin-left: -2rem;
}
.btnCtn {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  gap: 2rem;
  position: relative;
  // padding: 1rem;
  margin: 60px auto;
}

.btn_wrapper {
  button {
    outline: none;
    /* border: 1px solid #29815c; */
    background: transparent;
    // padding: 1em 2em;
    padding: 1em;
    color: #c0d2d8;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.2rem;
    border: none;
    /* border-bottom: 5px solid #2a3d44; */
    z-index: 100;
    position: relative;
    transition: all 0.3s ease;
  }
  span {
    display: block;
    transition: all 0.3s ease;
    height: 2px;
    width: 100%;
    background-color: hsl(196, 58%, 40%);
    display: flex;
    align-items: flex-end;
    margin-bottom: 1rem;
  }

  &:hover {
    /* border-bottom: 20px solid #2a3d44; */
    button {
      transform: translateY(-10px);
    }
    // padding-bottom: 40px;
    span {
      transform: translateY(-10px);
      padding-top: 10px;
    }
  }
}

.home {
  .bg_title {
    font-size: clamp(100px, 20vw, 358px);
    letter-spacing: 1rem;
    // letter-spacing: 5rem;
    font-weight: 900;
    line-height: 1;
    background: -webkit-linear-gradient(
      270deg,
      hsl(196, 58%, 13%),
      hsl(196, 58%, 8%)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%);

    // display: none;
  }
}
