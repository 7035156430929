body {
  margin: 0 auto;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: hsl(196, 58%, 8%);
  max-width: 1920px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
  margin: 0;
  padding: 0;
}
input,
textarea {
  font-family: 'Lato', sans-serif;
}
