.contact {
  //   height: 100vh;
  background: hsl(196, 58%, 8%);
  padding: 5rem 0;

  h3 {
    font-size: 2rem;
    color: white;
    @media (min-width: 768px) {
      margin-top: -5rem;
      padding-top: 0;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    gap: 1rem;
    align-items: center;
    input {
      width: 100%;
      border: none;
      background: rgb(255, 255, 255, 0.1);
      outline: none;
      padding: 1rem;
      font-size: 1rem;
      color: white;
      border-radius: 4px;
    }
    textarea {
      width: 100%;
      color: #fafafa;
      border-radius: 4px;
      border: none;
      outline: none;
      background: rgb(255, 255, 255, 0.1);
      font-size: 16px;
      padding: 1rem;
      resize: none;
    }
    button {
      padding: 1em 2em;
      font-size: 1rem;
      border: 1px solid hsl(196, 58%, 40%);
      outline: none;
      background: transparent;
      color: white;
      font-weight: 700;
      cursor: pointer;
      transition: all 0.6s ease;
      &:hover {
        background: hsl(195, 58%, 50%);
      }
    }
    .sent {
      font-size: 1.1rem;
      color: hsl(195, 58%, 50%);
    }
    .error {
      color: rgb(247, 139, 139);
    }
  }
}

.contactForm {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  flex-wrap: wrap;
  max-width: 1200px;
  align-items: center;
  padding: 5rem;
  background: hsl(196, 70%, 10%);
  box-shadow: 0 0 20px hsl(196, 58%, 8%);
  gap: 2rem;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  h2 {
    flex: 100%;
    text-align: center;
    font-size: 4rem;
    padding-bottom: 2rem;
    background: -webkit-linear-gradient(
      270deg,
      hsl(196, 58%, 40%) 10%,
      hsl(195, 58%, 8%)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h3 {
    flex: 30%;
  }
  form {
    flex: 50%;
  }
}
