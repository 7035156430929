@use './Home.scss';
@use './Nav.scss';
@use './About.scss';
@use './Portfolio.scss';
@use './Project.scss';
@use './Contact.scss';
@use './Footer.scss';

.table {
  margin-top: 5rem;
  width: 50%;
  padding: 2rem;
  table-layout: fixed;
  table {
    box-shadow: 0 20px 2px rgb(0, 0, 0, 0.2);
    padding: 2rem;
    padding-right: 0;
    width: 100%;
    min-width: 500px;
    overflow-x: scroll;
    // table-layout: fixed;
    th {
      text-align: start;
      span {
        display: inline-block;
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #f00;
        margin: 2px 5px;
      }
    }
    td,
    th {
      padding: 1rem 0;
      flex-basis: 15%;
      //   width: 15%;
    }
    tr {
      //   display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .account {
      //   width: 30%;
      flex: 20%;
    }
    .status {
      flex: 10%;
      //   flex: 1;
    }
  }
}
